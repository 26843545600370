<template>
  <header class="app-header">
    <div>
      <slot />
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppHeader'
};
</script>

<style>
.app-header {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 1rem;
}

.app-header > div {
  max-width: 60rem;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  align-content: flex-start;
}

.app-header ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.app-header ul li a {
  padding: 0 0.5rem;
  text-decoration: none;
  color: black;
}

.app-header ul {
  padding: 0;
  margin: 0;
}
</style>