
.card {
  border-radius: 50px;
  background: linear-gradient(145deg, #f0f0f0, #cacaca);
  box-shadow:  20px 20px 60px #bebebe,
               -20px -20px 60px #ffffff;
}


.app-header {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 1rem;
}
.app-header > div {
  max-width: 60rem;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  align-content: flex-start;
}
.app-header ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.app-header ul li a {
  padding: 0 0.5rem;
  text-decoration: none;
  color: black;
}
.app-header ul {
  padding: 0;
  margin: 0;
}


button[data-v-07cb4d] {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    padding: 1.5rem;

    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    border-radius: 50px;
    background: linear-gradient(145deg, #f0f0f0, #cacaca);
    box-shadow:  20px 20px 60px #bebebe,
                 -20px -20px 60px #ffffff;
    border: 2px solid #aaa9eb;
    transition: all ease-in 0.2s;
}
button[data-v-07cb4d]:hover {
    border: 2px solid #75749f;
}


* {
    box-sizing: border-box;
}
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: #e0e0e0;
    line-height: 1.5;
    overflow: hidden;
}
#cards {
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.card__word {
    margin: auto;
    font-size: 2.5rem;
    height: 200px;
    line-height: 200px;
    text-align: center;
}
.card__action,
  .card__translation {
    text-align: center;
    z-index: 0;
}
.card__translation {
    margin-bottom: 1rem;
    color: #8f8f8f
}
.card__translation small {
    margin-bottom: 1rem;
    color: #a9a9a9
}
.card__action > div {
    margin: 2rem 0 4rem;
    color: #a9a9a9;
    z-index: 1;
}


.card {
  border-radius: 50px;
  background: linear-gradient(145deg, #f0f0f0, #cacaca);
  box-shadow:  20px 20px 60px #bebebe,
               -20px -20px 60px #ffffff;
}


.app-header {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 1rem;
}
.app-header > div {
  max-width: 60rem;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  align-content: flex-start;
}
.app-header ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.app-header ul li a {
  padding: 0 0.5rem;
  text-decoration: none;
  color: black;
}
.app-header ul {
  padding: 0;
  margin: 0;
}


button[data-v-07cb4d] {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    padding: 1.5rem;

    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    border-radius: 50px;
    background: linear-gradient(145deg, #f0f0f0, #cacaca);
    box-shadow:  20px 20px 60px #bebebe,
                 -20px -20px 60px #ffffff;
    border: 2px solid #aaa9eb;
    transition: all ease-in 0.2s;
}
button[data-v-07cb4d]:hover {
    border: 2px solid #75749f;
}


* {
    box-sizing: border-box;
}
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: #e0e0e0;
    line-height: 1.5;
    overflow: hidden;
}
#cards {
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.card__word {
    margin: auto;
    font-size: 2.5rem;
    height: 200px;
    line-height: 200px;
    text-align: center;
}
.card__action,
  .card__translation {
    text-align: center;
    z-index: 0;
}
.card__translation {
    margin-bottom: 1rem;
    color: #8f8f8f
}
.card__translation small {
    margin-bottom: 1rem;
    color: #a9a9a9
}
.card__action > div {
    margin: 2rem 0 4rem;
    color: #a9a9a9;
    z-index: 1;
}

/*# sourceMappingURL=index.c7c0c59e.css.map */
