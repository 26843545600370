<template>
  <button
    :type="type"
    :class="`button _flex:center ${computedBlock} ${design} ${size} ${shape} ${color}`"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    block: {
      type: Boolean,
      default: false,
    },
    design: {
      type: String,
      default: 'solid',
    },
    shape: {
      type: String,
      default: 'rounded',
    },
    size: {
      type: String,
      default: 'sm',
    },
    type: {
      type: String,
      default: 'button',
    },
    color: {
      type: String,
      default: 'red',
    },
  },
  computed: {
    computedBlock() {
      return this.block === true ? 'block' : 'inline';
    },
  },
};
</script>

<style scoped>
  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    padding: 1.5rem;

    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    border-radius: 50px;
    background: linear-gradient(145deg, #f0f0f0, #cacaca);
    box-shadow:  20px 20px 60px #bebebe,
                 -20px -20px 60px #ffffff;
    border: 2px solid #aaa9eb;
    transition: all ease-in 0.2s;
  }

  button:hover {
    border: 2px solid #75749f;
  }
</style>