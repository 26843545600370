<template>
  <div class="card" :style="`width: ${size}; height: ${size}`">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    size: {
      type: String,
      default: '100px'
    }
  },
};
</script>

<style>
.card {
  border-radius: 50px;
  background: linear-gradient(145deg, #f0f0f0, #cacaca);
  box-shadow:  20px 20px 60px #bebebe,
               -20px -20px 60px #ffffff;
}
</style>