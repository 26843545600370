<template>
  <div>
    <AppHeader>
      <strong>800</strong>
      <ul>
        <li>
          <a href="#" @click="showCards">Flash Cards</a>
          <a href="#" @click="showList">All 800</a>
        </li>
      </ul>
    </AppHeader>
    <div v-if="cards" id="cards">
      <Card size="300px">
        <div class="card__word">
          {{ randomWord.catalan }}
        </div>
        <div class="card__translation">
          {{ randomWord.english }} <br> <small>Auto translated with google</small>
        </div>
      </Card>
      <div class="card__action">
        <div>Hit the spacebar to get a random word<br> or click the button</div>
        <Button @click="randomishCard">Show me a new word</Button>
      </div>
    </div>
    <div v-if="list" id="list">
      <pre>{{ words }}</pre>
    </div>
  </div>
</template>

<script>
import catalan800 from './assets/catalan800.json';
import Card from './components/card';
import AppHeader from './components/AppHeader';
import Button from './components/button';

export default {
  name: 'App',
  components: {
    Card,
    AppHeader,
    Button
  },
  data: () => ({
    words: [],
    seen: [],
    randomWord: {},
    cards: true,
    list: false
  }),
  mounted () {
    this.words = catalan800;
    this.randomishCard();
    window.addEventListener('keypress', (e) => {
      if(e.key == ' '){
        this.randomishCard();
      }
    });
  },
  methods: {
    showCards() {
      this.cards = true;
      this.list = false;
    },
    showList() {
      this.cards = false;
      this.list = true;
    },
    randomishCard() {
      this.randomWord = this.words[Math.floor(Math.random() * this.words.length)];
    }
  }
};

</script>

<style>
  * {
    box-sizing: border-box;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: #e0e0e0;
    line-height: 1.5;
    overflow: hidden;
  }

  #cards {
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .card__word {
    margin: auto;
    font-size: 2.5rem;
    height: 200px;
    line-height: 200px;
    text-align: center;
  }

  .card__action,
  .card__translation {
    text-align: center;
    z-index: 0;
  }

  .card__translation {
    margin-bottom: 1rem;
    color: #8f8f8f
  }

  .card__translation small {
    margin-bottom: 1rem;
    color: #a9a9a9
  }

  .card__action > div {
    margin: 2rem 0 4rem;
    color: #a9a9a9;
    z-index: 1;
  }
</style>